<template>
  <div class="favorites-mian-cen">
    <div class="favorites-eidt-mian">
      <el-dialog
        :title="$t('收藏夹编辑')"
        :visible.sync="editGroupShow"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div class="favorites-eidt-cen">
          <!-- 头部信息 -->
          <div class="favorites-eidt-head">
            <span>*</span>
            <div style="color: #BDBEBE;">{{$t('收藏夹名称')}}</div>
          </div>
          <!-- 输入框 -->
          <div>
            <el-input
              v-model="editGroupValue.group_name"
              :maxlength="20"
              :placeholder="$t('请输入名称')"
            ></el-input>
          </div>
          <!-- 生成按钮 -->
          <div class="favorites-eidt-bnt">
            <div @click="saveEidt">{{$t('确认修改')}}</div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editGroupShow: Boolean,
    editGroupValue: {},
  },
  data() {
    return {
      eidtInputValue: '',
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:editGroupShow', false)
    },
    saveEidt() {
      let params = this.editGroupValue
      params.group_name = params.group_name.trim()
      if (!params.group_name) {
        this.$notify({
          title: this.$t('失败'),
          message: this.$t('收藏夹名称不能为空'),
          type: 'error',
        })
        return
      }
      this.$service
        .editGroup(params)
        .then(() => {
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
          })
          this.handleClose()
          this.$emit('editGroupSuccess')
        })
        .catch((err) => {
          this.$notify({
            title: this.$t('失败'),
            message: err.message,
            type: 'error',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.favorites-mian-cen {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

// 样式穿透
// 样式穿透
::v-deep .favorites-eidt-mian .el-dialog__wrapper {
  width: 100%;
}
::v-deep .favorites-eidt-mian .el-dialog {
  border-left: 0.5rem solid #3e68ff;
  width: 24.125rem;
}
::v-deep .favorites-eidt-mian .el-dialog__body {
  padding: 0px 1.25rem;
}
.favorites-eidt-cen {
  display: flex;
  flex-flow: column;
}
.favorites-eidt-head {
  display: flex;
  flex-flow: row;
  font-size: 1rem;
  margin-top: 0.625rem;
  margin-bottom: 0.5rem;
}
.favorites-eidt-head > span {
  color: red;
}

.favorites-eidt-bnt {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  margin: 1rem 0rem;
}
.favorites-eidt-bnt > div {
  width: 6.75rem;
  height: 2.25rem;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 0.875rem;
}
.favorites-eidt-bnt > div:first-of-type {
  background: #ecf3fe;
  color: #3e68ff;
  margin-right: 0.875rem;
}
.favorites-eidt-bnt > div:last-of-type {
  background: #3e68ff;
  color: #ffffff;
}
.favorites-eidt-buttom {
  display: flex;
  flex-flow: column;
  color: #fd4a4a;
  margin-bottom: 1.75rem;
  font-size: 0.75rem;
}
</style>
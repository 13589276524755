<template>
  <div class="favorites-delete-mians">
    <div class="favorites-delete-mian">
      <el-dialog
        :title="$t('删除确认')"
        :visible.sync="deleteGroupShow"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div class="favorites-delete-cen">
          <!-- 头部信息 -->
          <div class="favorites-delete-head">
            <div>{{$t('确认删除该分组吗')}}?</div>
          </div>
          <!-- 生成按钮 -->
          <div class="favorites-delete-bnt">
            <div @click="deleteData">{{$t('删除')}}</div>
            <div @click="handleClose">取消</div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deleteGroupShow: Boolean,
    deleteId: Number,
  },
  data() {
    return {}
  },
  methods: {
    handleClose() {
      this.$emit('update:deleteGroupShow', false)
    },
    // 删除
    deleteData() {
      let params = {
        id: this.deleteId,
      }
      this.$service
        .deleteGroup(params)
        .then(() => {
          this.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success',
          })
          this.handleClose()
          this.$emit('deleteGroupSuccess', false)
        })
        .catch((err) => {
          this.$notify({
            title: this.$t('失败'),
            message: err.message,
            type: 'error',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.favorites-delete-mians {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

// 样式穿透
// 样式穿透
::v-deep .favorites-delete-mian .el-dialog__wrapper {
  width: 100%;
}

::v-deep .favorites-delete-mian .el-dialog {
  width: 24.125rem;
  border-left: 0.5rem solid #3e68ff;
}

::v-deep .favorites-delete-mian .el-dialog__body {
  padding: 0px 1.25rem;
}

::v-deep .favorites-delete-mian .el-dialog__header {
  padding: 1.25rem 1.25rem 0.3125rem;
}

.favorites-eidt-cen {
  display: flex;
  flex-flow: column;
}

.favorites-delete-head {
  display: flex;
  flex-flow: row;
  font-size: 1rem;
  color: #666666;
}

.favorites-delete-bnt {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  margin: 1rem 0rem;
}

.favorites-delete-bnt > div {
  width: 6.75rem;
  height: 2.25rem;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
}

.favorites-delete-bnt > div:first-of-type {
  background: #f23b31;
  color: #ffffff;
  margin-right: 0.875rem;
}

.favorites-delete-bnt > div:last-of-type {
  background: #ecf3fe;
  color: #3e68ff;
}
</style>

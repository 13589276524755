<template>
  <div class="favorites-mian">
    <!-- 头部 -->
    <public-header></public-header>
    <!-- 内容部分 -->
    <div class="page_bd">
      <!-- 头部筛选 -->
      <SearchHead @searchDataCk="searchDataCk"></SearchHead>
      <!-- 底部文件夹 -->
      <div ref="main" class="folder-cen">
        <div class="folder-cen-l">
          <!-- 头部添加按钮 -->
          <div class="folder-add-btn" @click="clickAddGroup">
            <div>{{$t('添加文件夹')}}</div>
            <i class="el-icon-circle-plus-outline"></i>
          </div>
          <!-- 文件夹列表 -->
          <div class="scroll">
            <vue-scroll ref="scroll" :ops="ops">
              <div class="folder-cen-list">
                <div
                  v-for="item in groupList"
                  :key="item.ID"
                  :class="[currentGroupId==item.ID?'active':'']"
                  @click="viewFolder(item.ID)"
                  class="folderitem"
                >
                  <div>{{item.group_name}}</div>
                  <div class="hoverTotal">{{item.total}}</div>

                  <div class="icon-operation-div">
                    <i class="el-icon-more"></i>
                    <div class="hoverDiv">
                      <div @click="eidtFavorites(item)">{{$t('编辑')}}</div>
                      <div @click="deleteFavorites(item.ID)">{{$t('删除')}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </vue-scroll>
          </div>
        </div>
        <div class="folder-cen-r" v-loading="loading">
          <!-- 头部检索 -->
          <div class="folder-cen-r-head">
            <div>
              <span>共收藏</span>
              <span class="total-number">{{total|formatNumber}}</span>
              <span>{{$t('家企业')}}</span>
            </div>
            <div>
              <el-dropdown split-button @command="dropdownCk" size="mini">
                {{orderText}}
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">{{$t('资本额降序')}}</el-dropdown-item>
                  <el-dropdown-item command="2">{{$t('资本额升序')}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- 输入框 -->
              <div class="folder-inputs">
                <el-input
                  placeholder="请输入内容"
                  prefix-icon="el-icon-search"
                  v-model="searchDatas.name"
                  @change="inputValue"
                  size="mini"
                ></el-input>
              </div>
            </div>
          </div>
          <!-- 	筛选内容 -->
          <Content
            v-bind:contentData="contentData"
            @overloadCk="overloadCk"
            v-bind:isshowData="isPl"
            @fatherCk="fatherCk"
          ></Content>
          <!-- 分页 -->
          <el-pagination
            background
            layout="total,prev, pager, next"
            :page-size="page_size"
            :total="total"
            :current-page.sync="page"
            @current-change="pageChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- 回到顶部 -->
    <back-top></back-top>

    <addGroup :addGroupShow.sync="addGroupShow" @addGroupSuccess="getUserGroupList"></addGroup>
    <deleteGroup
      :deleteGroupShow.sync="deleteGroupShow"
      :deleteId="deleteId"
      @deleteGroupSuccess="getUserGroupList"
    ></deleteGroup>
    <editGroup
      :editGroupShow.sync="editGroupShow"
      :editGroupValue="editGroupValue"
      @editGroupSuccess="getUserGroupList"
    ></editGroup>
    <public-footer></public-footer>
  </div>
</template>

<script>
//组件引入
import SearchHead from '../../components/search/search_head.vue'
import Content from '../../components/body/content.vue' //内容
import addGroup from '../../components/modeOpen/addGroup.vue' //创建文件夹
import deleteGroup from '../../components/modeOpen/deleteGroup.vue' // 删除文件夹
import editGroup from '../../components/modeOpen/editGroup.vue' // 编辑文件夹
export default {
  data() {
    return {
      page: 1,
      page_size: 10,
      total: 0, //分页总数
      addGroupShow: false, //创建文件夹变量
      deleteGroupShow: false,
      deleteId: -1,
      editGroupShow: false,
      editGroupValue: {
        id: '',
        group_name: '',
      },
      groupList: [], //用户文件夹
      currentGroupId: '',
      contentData: [], //收藏夹数组传入子组件content
      searchDatas: {
        address: '',
        bonus: [],
        labels: [],
        parent_path: '',
        total_capital_lower_limit: null,
        total_capital_upper_limit: null,
        type: [],
        name: '',
        order: 'desc',
      },
      isAddFavorites: false,
      companies_id: [], //批量生成公司id
      isPl: false,
      allOp: {
        isTrue: false,
        isSave: 1, //1批量添加,2批量修改
      }, // 开启批量收藏弹窗
      ops: {
        vuescroll: {},
        rail: {
          keepShow: true,
        },
        scrollPanel: {
          scrollingX: false,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#F5F5F5', //滚动条颜色
          opacity: 0.5, //滚动条透明度
          'overflow-x': 'hidden',
        },
      },
      loading: false,
    }
  },
  computed: {
    orderText() {
      if (this.searchDatas.order == 'desc') {
        return this.$t('资本额降序')
      } else if (this.searchDatas.order == 'asc') {
        return this.$t('资本额升序')
      } else {
        return this.$t('默认排序')
      }
    },
  },
  mounted() {
    //获取文件夹
    this.init()
  },
  methods: {
    init() {
      this.getUserGroupList()
    },
    //创建文件夹
    clickAddGroup() {
      this.addGroupShow = true
    },
    // 获取文件夹
    getUserGroupList() {
      this.$service.getGroupList().then((res) => {
        this.groupList = res.collection_group_vo || []
        if (this.groupList.length) {
          let currentGroup = this.groupList.find(
            (i) => i.ID == this.currentGroupId
          )
          if (!currentGroup) {
            this.currentGroupId = this.groupList[0].ID
          }
          this.getUserGroupCompanyList()
        }
      })
    },
    //获取用户收藏数据
    getUserGroupCompanyList() {
      this.loading = true
      let params = this.searchDatas
      params.group_id = this.currentGroupId
      params.page = this.page
      params.page_size = this.page_size
      this.$service
        .getGroupCompanyList(params)
        .then((res) => {
          this.contentData = res.rows
          this.total = res.total
          this.loading = false
        })
        .catch(() => {
          this.contentData = []
          this.total = 0
          this.loading = false
        })
    },
    //编辑文件夹
    eidtFavorites(e) {
      this.editGroupValue.id = e.ID
      this.editGroupValue.group_name = e.group_name
      this.editGroupShow = true
    },
    // 删除文件夹
    deleteFavorites(e) {
      this.deleteGroupShow = true
      this.deleteId = e
    },
    // 查看文件夹内容
    viewFolder(e) {
      this.currentGroupId = e
      this.page = 1
      this.getUserGroupCompanyList()
    },
    pageChange() {
      this.getUserGroupCompanyList()
      this.$refs['main'].scrollIntoView()
    },
    overloadCk(val) {
      if (val == true) {
        this.getUserGroupCompanyList()
        this.getUserGroupList()
      }
    },
    searchDataCk(val) {
      this.searchDatas = val
      this.getUserGroupCompanyList()
    },
    dropdownCk(e) {
      if (e == 1) {
        this.searchDatas.order = 'desc'
      } else {
        this.searchDatas.order = 'asc'
      }
      this.getUserGroupCompanyList()
      return
    },
    inputValue() {
      this.getUserGroupCompanyList()
    },
    fatherCk(val) {
      this.getUserGroupList()
      this.getUserGroupCompanyList()
    },
  },
  components: {
    SearchHead,
    Content,
    addGroup,
    deleteGroup,
    editGroup,
  },
}
</script>

<style lang="scss" scoped>
.favorites-mian {
  .folder-cen {
    display: flex;
    margin: 20px 0;
  }

  .folder-cen-l {
    position: sticky;
    top: 70px;
    width: 18.5rem;
    height: 80vh;
    margin-right: 24px;
    background: #ffffff;
    font-size: 0.875rem;
    font-weight: 400;

    .folder-add-btn {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      height: 45px;
      padding: 5px 1.25rem 0;
      line-height: 45px;
      font-size: 1rem;
      border-bottom: 1px solid #f9f9f9;
      font-weight: 800;
      color: #257cff;
      cursor: pointer;
    }
    .el-icon-circle-plus-outline {
      font-size: 24px;
    }
    .scroll {
      height: calc(100% - 50px);
    }
    .folder-cen-list {
      .folderitem {
        display: flex;
        flex-flow: row;
        height: 2.75rem;
        justify-content: space-between;
        align-items: center;
        color: #666666;
        padding: 0rem 1.25rem;
        cursor: pointer;
        &.active {
          background: #e6f5ff;
          color: #3e68ff;
        }
        & > div:first-of-type {
          width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .hoverTotal {
          padding-right: 6px;
        }
        &:hover .icon-operation-div {
          display: block;
        }
        &:hover .hoverTotal {
          display: none;
        }

        .icon-operation-div {
          display: none;
          height: 44px;
          line-height: 44px;
          position: relative;
          cursor: pointer;
          .el-icon-more {
            color: #666;
            transform: rotate(90deg);
            margin-right: 2px;
          }
          &:hover .hoverDiv {
            display: block;
          }
        }

        // 文件夹管理模块
        .hoverDiv {
          display: none;
          width: 88px;
          background: #ffffff;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
          position: absolute;
          padding: 0.9375rem 0rem;
          top: 2.625rem;
          right: -0.625rem;
          border-radius: 0.25rem;
          z-index: 30;
          color: #999999;
        }

        .hoverDiv > div {
          height: 2rem;
          text-align: center;
          line-height: 2rem;
        }

        .hoverDiv > div:hover {
          background: #3e68ff;
          color: #ffffff;
        }
      }
    }
  }

  .folder-cen-r {
    width: 880px;
    background: #ffffff;
  }
  .folder-cen-r-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 10px;
    margin: 0rem 1rem;
    border-bottom: 1px solid #f2f2f2;
    color: #333;
    font-size: 14px;
    .total-number {
      color: #007aff;
      font-weight: 800;
      padding: 0 4px;
    }
    .folder-inputs {
      display: inline-block;
      margin-left: 10px;
    }
  }
  .el-pagination {
    margin: 50px 0 40px 0;
    text-align: center;
  }
}
</style>
